<template>
  <v-scale-transition origin="bottom center 0">
    <v-btn v-if="showScrollUp" fab :large="defaultStyle" :bottom="defaultStyle" :right="defaultStyle"
           :class="defaultStyle ? 'scrollUp' : ''"
           color="accent" @click="activateScroll">
      <v-icon color="#FFFFFF" large>mdi-chevron-up</v-icon>
    </v-btn>
  </v-scale-transition>
</template>

<script>
export default {
  name: 'ScrollUp',
  props: {
    currentPosition: { type: Number, required: true },
    threshold: { type: Number, required: true },
    defaultStyle: { type: Boolean, default: true }
  },
  data: () => ({
    scrollPosition: null
  }),
  computed: {
    showScrollUp () {
      return this.currentPosition > this.threshold
    }
  },
  methods: {
    activateScroll () {
      this.$gtag.event('scroll_up_' + this.$route.path, { value: 1 })
      this.$emit('scrollUp')
    }
  }
}
</script>

<style scoped>
  .scrollUp {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;
    z-index: 2;
  }

</style>
