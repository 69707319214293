<template>
  <span>
    <v-navigation-drawer v-model="showCart" temporary fixed hide-overlay right color="component" width="310px"
                         style="z-index: 25; overflow: hidden">
      <span slot="prepend">
        <v-row class="my-7 px-8 align-center">
          <v-icon color="accent" class="mr-3">mdi-calendar</v-icon>
          <v-toolbar-title class="mb-0">Timetable Cart</v-toolbar-title>
          <v-spacer/>
          <v-icon class="mr-1" @click="toggleCart(false)">mdi-close</v-icon>
        </v-row>
        <v-divider class="my-2"/>
        <v-row class="pb-4 pt-3 px-8 align-baseline">
          <p class="mb-0 pl-1 mr-1 font-weight-medium text--secondary">Sort by</p>
          <v-menu bottom offset-y nudge-bottom="10">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="transparent" class="text-none pl-2 pr-1 text-body-1 font-weight-medium" depressed v-bind="attrs" v-on="on">
                {{ sortCart.options[sortCart.selected].label }}<v-spacer/>
                <v-icon color="#ADADAD" class="pl-2">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(option, idx) in sortCart.options" :key="idx" @click="sortBy(option.value)">
                <v-list-item-title>
                  <v-icon color="accent" size="20" class="mr-3">{{ option.icon }}</v-icon>{{ option.label }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer/>
          <p v-if="timetableCourses.length > 0" class="mb-0 mr-1 text-body-2 hover accent--text font-weight-medium"
             @click="clearCart">Clear All</p>
        </v-row>
      </span>

      <v-list :flat="!store.app.darkMode" class="pb-12 mb-12 overflow-y-auto">
        <v-list-item v-for="(course, idx) in timetableCourses" :key="idx+'A'">
          <v-card outlined class="mt-3 pl-2 py-2 rounded-lg" style="border-radius: 0;" width="100%">
            <v-col class="pt-4 pb-1">
              <v-row class="pl-3 pr-2 py-0 align-center">
                <p class="text-body-1 font-weight-medium mb-0 mr-3">{{ course.data.courseResult.code }}</p>
                <v-spacer/>
                <SemesterChips :semesters="[course.lecture.section.slice(0, 1)]" icon/>
                <v-icon class="mx-1" @click="store.data.deleteTimetableEntry([course.data.courseResult.code, course.lecture.section.slice(0, 1)])">mdi-close</v-icon>
              </v-row>
              <p class="text-body-2 mb-0 mt-4 font-weight-medium">{{ course.lecture.section.slice(5) }}</p>
              <p v-for="(time, idx) in course.lecture ? course.lecture.times : []" :key="idx+'B'"
                 class="text-body-2 mb-0 text--secondary">{{ time.day }} • {{ time.time }}
              </p>
              <p v-if="course.lecture && course.lecture.times.length < 1" class="text-body-2 mb-0 text--secondary">
                {{ course.lecture.delivery }}
              </p>
              <p v-if="course.tutorial" class="text-body-2 mb-0 mt-2 font-weight-medium">{{ course.tutorial.section.slice(5) }}</p>
              <p v-for="(time, idx) in course.tutorial ? course.tutorial.times : []" :key="idx+'C'"
                 class="text-body-2 mb-0 text--secondary">{{ time.day }} • {{ time.time }}
              </p>
            </v-col>
          </v-card>
        </v-list-item>
      </v-list>
      <v-col class="fixedBottom">
        <v-btn class="px-3" depressed color="#003C85" style="width: inherit" large @click="$router.push('/timetable')">
          <p class="mb-0 white--text text-body-1 font-weight-medium">View Timetable ({{ timetableCourses.length }})</p>
        </v-btn>
      </v-col>
    </v-navigation-drawer>

    <v-btn v-if="!store.app.onMobile && currentPosition <= threshold && timetableCourses.length > 0 &&
                  store.app.customFABPath.indexOf($route.path) >= 0"
           fab :fixed="!fab" :bottom="!fab" :right="!fab" large color="accent" :style="buttonStyling" @click="toggleCart(true)">
      <v-badge :content="timetableCourses.length" color="warning" overlap>
        <v-icon large>mdi-calendar</v-icon>
      </v-badge>
    </v-btn>
    <v-speed-dial v-if="!store.app.onMobile && currentPosition > threshold &&
                         timetableCourses.length > 0 && store.app.customFABPath.indexOf($route.path) >= 0"
                  v-model="fab" class="FAB" absolute bottom right direction="top" open-on-hover transition="slide-y-reverse-transition">
      <template v-slot:activator>
        <v-btn v-model="fab" color="accent" active-class="warning" fab large>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-apple-keyboard-command</v-icon>
        </v-btn>
      </template>
      <!-- FAB - Scroll up btn when scrolled passed a certain threshold -->
      <ScrollUp :current-position="currentPosition" :threshold="500" :default-style="false"
                @scrollUp="$scrollTo('#topOfPage', { duration: 350 })"/>

      <v-btn fab :fixed="!fab" :bottom="!fab" :right="!fab" color="accent" @click="toggleCart(true)">
        <v-badge :content="timetableCourses.length" color="warning" overlap>
          <v-icon large>mdi-calendar</v-icon>
        </v-badge>
      </v-btn>
    </v-speed-dial>
  </span>

</template>

<script>
import ScrollUp from '@/components/ScrollUp'
import SemesterChips from '@/components/SemesterChips.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'TimetableCart',
  components: { SemesterChips, ScrollUp },
  props: {
    currentPosition: { type: Number, required: true },
    threshold: { type: Number, required: true }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    showCart: false,
    fab: false,
    sortCart: {
      selected: 0,
      options: [
        { label: 'Semester', icon: 'mdi-clock', value: 1 },
        { label: 'Course', icon: 'mdi-school', value: 2 }
      ]
    },
    entryToEdit: { course: '', semester: 0, selected: {} }
  }),
  computed: {
    timetableCourses () {
      return this.store.data.timetableCourses.filter(course => !course.isBlockedTime)
    },
    buttonStyling () {
      if (this.currentPosition > this.threshold) {
        return 'margin-bottom: 85px; margin-right: 4px'
      } else {
        return 'margin: 6px 4px'
      }
    }
  },
  methods: {
    toggleCart (view) {
      if (view) {
        // GTAG
        this.$gtag.event('timetable_cart_view', { value: 1 })
        this.sortBy(this.sortCart.options[this.sortCart.selected].value)
      }
      this.showCart = view
    },
    sortBy (option) {
      this.sortCart.selected = option - 1
      if (option === 1) {
        this.store.data.timetableCourses.sort(function compare (a, b) {
          const aSem = a.lecture.section.slice(0, 1)
          const bSem = b.lecture.section.slice(0, 1)
          if ((aSem === 'S') || (aSem === 'W' && bSem === 'F')) return 1
          if ((aSem === 'F') || (aSem === 'W' && aSem === 'S')) return -1
          return 0
        })
      } else {
        this.store.data.timetableCourses.sort((a, b) => (a.data.courseResult.code > b.data.courseResult.code) ? 1 : -1)
      }
    },
    clearCart () {
      // GTAG
      this.$gtag.event('timetable_cart_clear', { value: 1 })
      this.store.data.clearTimetableEntry(null)
      this.showCart = false
    }
  }
}
</script>

<style scoped>
  .FAB {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;
    z-index: 2;
  }
  .hover:hover {
    cursor: pointer;
    color: #0789F2 !important;
    text-decoration: underline;
  }

</style>
