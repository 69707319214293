<template>
  <v-container class="pa-0 mx-0" style="width: auto;">
    <v-dialog v-model="store.app.commandModal" overlay-color="transparent"
              :fullscreen="store.app.onMobile" max-width="800px" @click:outside="resetModal">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px" loader-height="5"  height="500px" class="relative">
        <!-- COMMAND PANEL TEXT FIELD -->
        <v-text-field class="ma-0 px-2 pt-2 pb-1 commandTextField" v-model="query" ref="commandField" @focus="commandTab.active = 0"
                      hide-details solo flat autofocus color="accent" placeholder="Search for courses, saved timetables, and pages"/>
        <!-- COMMAND RESULTS SECTION -->
        <div class="overflow-y-auto" style="height: 435px">
          <!-- COMMAND TABS -->
          <v-row class="ma-0 px-4 py-1 align-center" ref="commandTabs">
            <v-chip-group v-model="commandTab.active" mandatory class="pa-0" active-class="accent--text font-weight-medium">
              <v-chip v-for="(tab, tabIdx) in commandTab.options" :key="tabIdx" filter filter-icon="" label
                      :color="commandTab.active === tabIdx ? 'transparent' : 'border'" class="mr-3">
                <v-icon class="mr-1" size="20">{{ tab.icon }}</v-icon>{{ tab.name }}
              </v-chip>
            </v-chip-group>
          </v-row>
          <!-- SEARCH RESULTS -->
          <div v-if="commandTab.active === 0">
            <div>
              <p class="font-weight-medium px-4 pt-3 mb-2 d-flex flex-row align-center text--secondary">Courses
                <span class="text--secondary text-body-2 ml-2">({{ courses.length }} results)</span>
                <v-spacer/>
                <span v-if="courses.length <= 100 && courses.length > courseSlice" class="accent--text text-body-2 pointer"
                      @click="courseSlice = courses.label">Show all</span>
              </p>
              <v-list v-if="courses.length" class="mx-2">
                <v-list-item v-for="(course, idx) in courses.slice(0, courseSlice)" :key="computeIDX(0, idx)[0]"
                             :id="'i' + computeIDX(0, idx)[0]" :class="computeIDX(0, idx)[1] ? 'activeItem px-3' : 'px-3'"
                             @click="selectItem(formatItemEntry(0, course))">
                  <v-list-item-title class="d-flex flex-row align-center">
                    <v-icon color="accent" size="18" class="mr-3">mdi-book-open-page-variant</v-icon>
                    <span class="overflow-ellipsis" style="width: 650px">{{ course }}</span>
                    <v-spacer/>
                    <v-chip disabled label small>Course</v-chip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <p v-else class="text--secondary text-body-2 px-4 mb-0 text-center">No courses found</p>
            </div>
            <div v-if="store.user.userInfo">
              <p class="font-weight-medium px-4 pt-3 mb-2 text--secondary d-flex flex-row align-center">Timetables
                <v-divider class="ml-12"/>
              </p>
              <v-list v-if="timetables.length" class="mx-2">
                <v-list-item v-for="(schedule, idx) in timetables.slice(0, 5)" :key="computeIDX(1, idx)[0]"
                             :id="'i' + computeIDX(1, idx)[0]" :class="computeIDX(1, idx)[1] ? 'activeItem px-3' : 'px-3'"
                             @click="selectItem(formatItemEntry(1, schedule))">
                  <v-list-item-title class="d-flex flex-row align-center">
                    <v-icon color="warning" size="18" class="mr-3">mdi-calendar</v-icon>
                    {{ schedule.name }}
                    <span class="text--secondary text-body-2 ml-4">Last updated {{ new Date(schedule.updatedAt).toDateString() }}</span>
                    <v-spacer/>
                    <v-chip disabled label small>Timetable</v-chip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <p v-else class="text--secondary text-body-2 px-4 mb-0 text-center">No timetables found</p>
            </div>
            <div>
              <p class="font-weight-medium px-4 pt-3 mb-2 text--secondary d-flex flex-row align-center">Webpages
                <v-divider class="ml-12"/>
              </p>
              <v-list v-if="pages.length" class="mx-2">
                <v-list-item v-for="(page, idx) in pages.slice(0, 5)" :key="computeIDX(2, idx)[0]"
                             :id="'i' + computeIDX(2, idx)[0]" :class="computeIDX(2, idx)[1] ? 'activeItem px-3' : 'px-3'"
                             @click="selectItem(formatItemEntry(2, page))">
                  <v-list-item-title class="d-flex flex-row align-center">
                    <v-icon color="error" size="18" class="mr-3">mdi-web</v-icon>
                    {{ page.page }}
                    <span class="text--secondary text-body-2 ml-4">{{ page.link }}</span>
                    <v-spacer/>
                    <v-chip disabled label small>Page</v-chip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <p v-else class="text--secondary text-body-2 px-4 text-center">No pages found</p>
            </div>
          </div>
          <!-- RECENT ITEMS -->
          <div v-else-if="commandTab.active === 1">
            <p class="font-weight-medium px-4 pt-3 mb-2 text--secondary d-flex flex-row align-center">
              {{ commandTab.options[commandTab.active].name }}
            </p>
            <v-list v-if="recentItems.length" class="mx-2">
              <v-list-item v-for="(item, idx) in recentItems" :key="computeIDX(0, idx)[0]" :id="'i' + computeIDX(0, idx)[0]"
                           :class="computeIDX(0, idx)[1] ? 'activeItem px-3' : 'px-3'" @click="selectItem(item)">
                <v-list-item-title class="d-flex flex-row align-center">
                  <v-icon :color="itemOptions[item.type.value].colour" size="18" class="mr-2">
                    {{ itemOptions[item.type.value].icon }}
                  </v-icon>
                  <span class="overflow-ellipsis" style="max-width: 650px">{{ item.title }}</span>
                  <span v-if="item.subtitle" class="text--secondary text-body-2 ml-4">{{ item.subtitle }}</span>
                  <v-spacer/>
                  <v-chip disabled label small>{{ item.type.label }}</v-chip>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <p v-else class="text--secondary text-body-2 px-4 mb-0 text-center">No recent items</p>
          </div>
          <!-- APP SETTINGS -->
          <div v-else>
            <p class="font-weight-medium px-4 pt-3 mb-2 text--secondary d-flex flex-row align-center">
              {{ commandTab.options[commandTab.active].name }}
            </p>
            <v-list class="mx-2">
              <v-list-item class="align-center px-3" link :ripple="false">
                <v-list-item-title>
                  <v-icon color="accent" size="18" class="mr-2">mdi-theme-light-dark</v-icon>
                  Dark Mode
                </v-list-item-title>
                <v-spacer/>
                <v-switch v-model="store.app.darkMode" color="accent" hide-details inset/>
              </v-list-item>
              <v-list-item class="align-center px-3" link :ripple="false">
                <v-list-item-title>
                  <v-icon color="accent" size="18" class="mr-2">mdi-shape</v-icon>
                  Homepage Live Animations
                </v-list-item-title>
                <v-spacer/>
                <v-switch v-model="store.app.liveAnimations" color="accent" hide-details inset/>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'CommandModal',
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    query: null,
    loading: false,
    courseSlice: 5,
    currentItem: -1,
    itemOptions: {
      0: { title: 'Courses', icon: 'mdi-book-open-page-variant', colour: 'accent' },
      1: { title: 'Timetables', icon: 'mdi-calendar', colour: 'warning' },
      2: { title: 'Webpages', icon: 'mdi-web', colour: 'error' }
    },
    commandTab: {
      active: 0,
      options: [
        { name: 'Quick Search', icon: 'mdi-magnify' },
        { name: 'Recent Items', icon: 'mdi-history' },
        { name: 'App Settings', icon: 'mdi-cog-outline' }
      ]
    },
    recentItems: [],
    schedules: [],
    webpages: [
      { page: 'Directory', link: '/directory', internal: 1, new: false },
      { page: 'Timetable', link: '/timetable', internal: 1, new: false },
      { page: 'Course Tree', link: '/tree', internal: 1, new: false },
      { page: 'Drop Rates', link: '/droprate', internal: 1, new: false },
      { page: 'Compare', link: '/compare', internal: 1, new: false },
      { page: 'About', link: '/about', internal: 1, new: false },
      { page: 'Feedback & Report', link: '/feedback', internal: 1, new: false },
      { page: 'Roadmap', link: 'https://uoftindex.canny.io/', internal: 0, new: false },
      { page: 'Changelog', link: '/changelog', internal: 1, new: false },
      { page: 'Privacy Policy', link: '/privacy', internal: 1, new: false },
      { page: 'Terms of Use', link: '/terms', internal: 1, new: false },
      { page: 'Disclaimers', link: '/disclaimers', internal: 1, new: false }
    ]
  }),
  mounted () {
    document.addEventListener('keydown', this.navigateItems)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.navigateItems)
  },
  computed: {
    courses () {
      this.resetSlice()
      if (this.query) {
        return this.store.data.courseList.filter(course => course.toLocaleLowerCase().indexOf(this.query.toLocaleLowerCase()) > -1)
      } else {
        return this.store.data.courseList
      }
    },
    timetables () {
      if (this.schedules.length && this.query) {
        return this.schedules.filter(schedule => schedule.name.toLocaleLowerCase().indexOf(this.query.toLocaleLowerCase()) > -1)
      } else {
        return this.schedules
      }
    },
    pages () {
      if (this.query) {
        return this.webpages.filter(page => page.page.toLocaleLowerCase().indexOf(this.query.toLocaleLowerCase()) > -1)
      } else {
        return this.webpages
      }
    },
    maxNumItems () {
      return this.courses.slice(0, this.courseSlice).length + this.timetables.slice(0, 5).length + this.pages.slice(0, 5).length - 1
    }
  },
  watch: {
    'store.app.commandModal': function (newValue) {
      if (newValue && this.store.user.userInfo) this.getSchedules()
    },
    'commandTab.active': function (newValue) {
      this.$gtag.event('command_panel_tab_' + newValue, { value: 1 })
      this.currentItem = -1
      this.query = null
    }
  },
  methods: {
    computeIDX (section, initialValue) {
      let idx
      if (section === 0) {
        idx = initialValue
      } else if (section === 1) {
        idx = initialValue + this.courses.slice(0, this.courseSlice).length
      } else {
        idx = initialValue + this.courses.slice(0, this.courseSlice).length + this.timetables.slice(0, 5).length
      }
      return [idx, idx === this.currentItem]
    },
    async getSchedules () {
      this.schedules = this.store.user.userInfo ? await (await fetch('/rest/v1/user/mySchedules')).json() : []
    },
    formatItemEntry (section, item) {
      let formattedEntry
      if (section === 0) {
        formattedEntry = {
          type: { value: 0, label: 'Course' },
          title: item,
          subtitle: '',
          page: item.slice(0, 8)
        }
      } else if (section === 1) {
        formattedEntry = {
          type: { value: 1, label: 'Timetable' },
          title: item.name,
          subtitle: 'Last updated ' + new Date(item.updatedAt).toDateString(),
          page: item._id
        }
      } else {
        formattedEntry = {
          type: { value: 2, label: 'Page' },
          title: item.page,
          subtitle: item.link,
          page: item
        }
      }
      return formattedEntry
    },
    selectItem (item) {
      let tag
      if (item.type.value === 0) {
        tag = 'command_panel_' + item.page
        this.$router.push({ path: '/courses', query: { c: item.page } })
      } else if (item.type.value === 1) {
        tag = 'command_panel_timetable'
        this.$router.push('/t/' + item.page)
      } else {
        if (item.page.internal) {
          tag = 'command_panel_' + item.page.link.slice(1)
          this.$router.push(item.page.link)
        } else {
          window.open(item.page.link, '_blank').focus()
        }
      }
      const existingEntry = this.recentItems.findIndex(entry => JSON.stringify(entry) === JSON.stringify(item))
      if (existingEntry > -1) this.recentItems.splice(existingEntry, 1)
      this.$gtag.event(tag, { value: 1 })
      this.recentItems.unshift(item)
      this.resetModal()
    },
    navigateItems (e) {
      if (this.store.app.commandModal) {
        if (e.keyCode === 38 && this.currentItem > 0) {
          this.currentItem--
          document.querySelector('#i' + this.currentItem).scrollIntoView()
        } else if (e.keyCode === 40 && this.currentItem < this.maxNumItems) {
          this.currentItem++
          document.querySelector('#i' + this.currentItem).scrollIntoView(false)
        } else if (e.keyCode === 13) {
          document.querySelector('#i' + this.currentItem).click()
        } else {
          if (this.currentItem < 1 && this.$refs.commandTabs) this.$refs.commandTabs.scrollIntoView()
        }
      }
    },
    resetModal () {
      this.store.app.commandModal = false
      this.courseSlice = 5
      this.currentItem = -1
      this.query = null
      this.$gtag.event('command_panel_toggled', { value: 1 })
    },
    resetSlice () { this.courseSlice = 5 }
  }
}
</script>

<style scoped>
  >>>.v-list-item {
    min-height: 43px !important;
  }
  >>>.v-list-item__icon {
    margin-right: 2px !important;
  }
  >>>.v-slide-group__content {
    padding: 0 !important;
  }
  >>>.v-input.v-text-field>.v-input__control>.v-input__slot:before {
    border: none;
  }
  >>>.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
  .commandTextField {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--v-component-base);
    border-bottom: 2px solid var(--v-border-base)
  }
  .activeItem {
    background-color: var(--v-border-base);
  }
</style>
