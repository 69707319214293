<template>
  <v-avatar data-cy="user_avatar" :color="color" :size="imgSize" :class="{'avatarContainer' : showBg, 'showBorder': showBorder}">
    <img v-if="pictureUrl" :src="pictureUrl" />

    <span v-else :style="`font-size: ${size/3}pt`"> {{ userInitials }} </span>
  </v-avatar>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'

export default {
  props: ['img', 'color', 'size', 'showBg', 'showBorder'],
  setup () {
    return {
      store: useAllStores()
    }
  },
  data () {
    return {
      imgSize: this.size || 36,
      pictureUrl: this.img || this.store.user.userInfo?.picture
    }
  },
  computed: {
    userInitials () {
      const name = this.store.user.userInfo?.displayName

      if (name) {
        const segments = name.split(' ')
        if (segments.length > 1) return segments[0][0].toUpperCase() + segments[1][0].toUpperCase()
        else return segments[0][0].toUpperCase()
      }

      // User email is always defined, except in dev when hot reloading...hacky...
      return (this.store.user.userInfo?.email || ' ')[0].toUpperCase()
    }
  }
}
</script>

<style scoped>
.avatarContainer {
  background: white;
}

.showBorder {
  border: solid 3px #E0E0E0;
}

span { color: black; }
</style>
